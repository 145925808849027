<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'

import draggable from "vuedraggable";

import DragIcon from '@/assets/icons/DragIcon.vue';
import DeleteIcon from '@/assets/icons/DeleteIcon.vue';
import SearchIcon from '@/assets/icons/SearchIcon.vue';
import Button from '@/components/Button/Button.vue';
import PlusIcon from '@/assets/icons/PlusIcon.vue';
import PlusIconGrey from '@/assets/icons/PlusIconGrey.vue';

const { useGetters, useMutations } = createVuexHelpers()
const { tableTimeGroups } = useGetters('dictionary', ['tableTimeGroups'])

const props = defineProps({
  filterGroups: {
    default: []
  },
  timeGroups: {
    default: []
  }
})

const timeGroupingObject = ref({});

const tableTimeGroupsArrayByKey = computed(() => {
  tableTimeGroups.value.map((groupItem) => {
    timeGroupingObject.value[groupItem.key] = groupItem.name;
  })
})

const emits = defineEmits(['updateFilter']);

const MAX_GROUP_ITEMS = 3;

const $t = useI18n()

const listGrouping = ref([]);
const filterGroupModel = ref(null);
const openedGroupFilters = ref(false)
const addToGroupFilterBlock = ref(false);

const timeGroupsList = computed(() => {
  return props.timeGroups.filter((item) => {
    if(filterGroupModel.value) {
      return item.value.toLowerCase().includes(filterGroupModel.value.toLowerCase()) && item.key !== 'no_grouping';
    }
    return item.key !== 'no_grouping'
  });
})

const draggbleDisabled = computed(() => {
  return listGrouping.value.length <= 1
})

const openGroupFilter = () => {
  if (openedGroupFilters.value) {
    return false;
  }

  openedGroupFilters.value = true
}

const deleteGroupItemByIndex = (index) => {
  openedGroupFilters.value = false;
  addToGroupFilterBlock.value = false;
  listGrouping.value.splice(index, 1);
  updateFilter()
}

const outsideGroupFilters = () => {
  openedGroupFilters.value = false;
}

const addToFilterGroup = (item) => {
  addToGroupFilterBlock.value = false;

  const groupInList = listGrouping.value.find((listItem) => {
    return listItem?.key === item?.key
  })

  if(groupInList) {
    listGrouping.value = listGrouping.value.filter((el) => {
      return el?.key !== item.key
    })

    updateFilter()
    return false;
  }

  listGrouping.value.push(item)

  if(listGrouping.value.length >= MAX_GROUP_ITEMS) {
    addToGroupFilterBlock.value = true;
    openedGroupFilters.value = false;

    updateFilter()
    return false;
  }

  updateFilter();
}

const showButtonTooltip = computed(() => {
  return !addToGroupFilterBlock.value
})
const hasOpenGroupFilter = computed(() => {
  return openedGroupFilters.value && !addToGroupFilterBlock.value
})

const checkGroupInList = (item) => {
  return listGrouping.value.find((listItem) => {
    return listItem?.key === item?.key
  })
}

const updateFilter = () => {
  emits('updateFilter', listGrouping.value)
}

const initFilterData = () => {
  props.filterGroups.map((key) => {
    props.timeGroups.map((groupItem) => {
      if(groupItem?.key === key) {
        listGrouping.value.push(groupItem)
      }
    })
  })

  if(props.filterGroups?.length >= MAX_GROUP_ITEMS) {
    addToGroupFilterBlock.value = true
  }
}

watch(
  () => openedGroupFilters.value,
  newValue => {
    if(newValue === false) {
      filterGroupModel.value = null
    }
  }
)

onMounted(() => {
  initFilterData();
})
</script>

<template>
  <div class="box mt-4">
    <p class="filter filter--title text-sm">
      {{ $t('reportsFilter.groupBy') }}
    </p>
    <div class="container">
      <draggable @end="updateFilter" :disabled="draggbleDisabled" v-model="listGrouping" tag="ul">
        <li v-for="(item, index) in listGrouping" :key="index">
          <DragIcon class="icon cursor-move"/>
          <span class="draggable-text">
            {{ item.name }}
          </span>
          <span
            @click="deleteGroupItemByIndex(index)"
            class="delete-icon">
            <DeleteIcon />
          </span>
        </li>
      </draggable>
      <div class="relative">
        <ElTooltip
          :disabled="showButtonTooltip"
          :content="$t('reportsFilter.byGroup.tooltip')"
          effect="dark" placement="bottom" :open-delay="500" :width="200" :offset="0" :visible-arrow="false">
          <Button
            @click.native="openGroupFilter"
            :disabled="addToGroupFilterBlock"
            centered>
            <template #left>
              <PlusIconGrey v-if="addToGroupFilterBlock"/>
              <PlusIcon v-else/>
            </template>
            {{ $t('reportsFilter.addGroup') }}
          </Button>
        </ElTooltip>
          <div
            class="group-filters"
            v-click-outside="outsideGroupFilters"
            v-if="hasOpenGroupFilter">
            <div class="group-filters__search search">
              <ElInput
                :placeholder="$t('search')"
                v-model="filterGroupModel"/>
              <SearchIcon class="search__icon"/>
            </div>
            <div>
              <ul class="group-filters__list overflow-y-auto">
                <li v-for="groupItem in timeGroupsList"
                  @click="addToFilterGroup(groupItem)"
                  >
                  {{ groupItem.name }}
                  <span class="group-filters__list--ok" v-if="checkGroupInList(groupItem)">
                    &#10003;
                  </span>
                </li>
                <li class="text-center no-hover" v-if="timeGroupsList.length < 1">
                  {{ $t('reportsFilter.byGroup.noSearch.msg') }}
                </li>
              </ul>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.no-hover {
  color: rgb(44, 62, 80) !important;
  cursor: default !important;
  background: $light-grey-tone-color !important;
}
.cursor-move {
  cursor: move;
}

.sortable-chosen.sortable-ghost {
  background: #e9eff3!important;
}

.box {
  .filter {

    &--title {
      position: relative;
      color: $grey-tone-second-color;
      margin-bottom: 10px;
      font-family: "Source Sans Pro", serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.8px;


      &:after {
        position: absolute;
        top: 50%;
        left: 100px;
        transform: translate(0%, -50%);
        content: '';
        width: 240px;
        height: 1px;
        background-color: $light-grey-third-tone-color;
      }
    }
  }

  .container {
    border: 1px solid #C9D6DF;
    padding: 4px;
    border-radius: 3px;
    width: 340px;

    ul {

      li {
        position: relative;
        display: flex;
        align-items: center;
        padding: 6px;
        background: $light-grey-tone-color;
        margin-bottom: 2px;
        border-radius: 4px;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        height: 32px;

        .icon {
          margin-right: 6px;
        }

        .delete-icon {
          opacity: .8;
          cursor: pointer;
          position: absolute;
          right: 10px;
          padding: 2px 5px;
          transition: opacity .12s;

          &:hover {
            opacity: 1;
            transition: opacity .12s;
          }
        }
      }
    }

    .draggable-text {
      cursor: move;
    }

    .relative {
      position: relative;
      z-index: 10;
    }

    .group-filters {
      position: absolute;
      background: $light-grey-tone-color;
      top: 44px;
      left: 0;
      width: 100%;
      border-radius: 12px;
      border: 1px solid #EDEFF3;
      overflow: hidden;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

      &__search {
        position: relative;
        padding: 8px;
      }

      .search {

        :deep(.el-input__inner) {
          padding-left: 30px;
          color: $main-title-color;
        }

        &__icon {
          position: absolute;
          left: 16px;
          top: 16px;
        }
      }

      &__list {
        max-height: 200px;
        background: $light-grey-tone-color;
        border-top: 1px solid #DCE1E7;

        li {
          padding: 7px 12px;
          margin-bottom: 0;
          display: block;

          &:hover {
            cursor: pointer;
            color: #3A7CCA;
            background: #fff;
          }
        }

        &--ok {
          float: right;
        }
      }
    }
  }
}
</style>
